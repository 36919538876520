import React from "react";
import { Link } from "gatsby";

import Layout from "../layouts/zh";
import Img_benefit_1 from "../images/home/benefit-1.svg";
import Img_benefit_2 from "../images/home/benefit-2.svg";
import Img_benefit_4 from "../images/home/benefit-4.svg";
import Img_benefit_5 from "../images/home/benefit-5.svg";
import Img_benefit_6 from "../images/home/benefit-6.svg";
import Img_benefit_8 from "../images/home/benefit-8.svg";
import SEO from "../components/seo";
import { HomeBanner } from "../components/Carousel";
import { useAuth0 } from "@auth0/auth0-react";

const IndexPage = (props) => {
  const rewardsUrl = {
    quarantine:
      "https://www.ayana.com/zh/bali/offers/cocooned-in-tranquility--ayana--quarantine-package?utm_source=baliwebsite&utm_medium=ayanarewardsheader&utm_campaign=quarantineoffers",
    long: "/zh/promotion/immersed-in-paradise",
    miles: "/zh/reward",
  };

  const { loginWithRedirect } = useAuth0();
  const isAuth0Enabled = process.env.GATSBY_ENABLE_AUTH0 === "true";

  return (
    <Layout location={props.location}>
      <SEO title="Home" />

      <HomeBanner rewardsUrl={rewardsUrl} />

      <div className="home__benefit text--center">
        <div className="container container--500">
          <h1>加入会员尊享福利</h1>
          <p>
            从免费住宿、豪华客房升级到诸多精致用餐体验，阿雅娜会员礼遇为您带来更多精彩奖励。
          </p>
        </div>
        <div className="container">
          <div className="home__benefit__list__wrapper">
            <div className="home__benefit__list">
              <div className="home__benefit__img">
                <img src={Img_benefit_1} />
              </div>
              <div className="home__benefit__list__title">额外奖励积分</div>
            </div>
            <div className="home__benefit__list">
              <div className="home__benefit__img">
                <img src={Img_benefit_2} />
              </div>
              <div className="home__benefit__list__title">会员专享特价房</div>
            </div>
            <div className="home__benefit__list">
              <div className="home__benefit__img">
                <img src={Img_benefit_4} />
              </div>
              <div className="home__benefit__list__title">免费升级房型</div>
            </div>
            <div className="home__benefit__list">
              <div className="home__benefit__img">
                <img src={Img_benefit_5} />
              </div>
              <div className="home__benefit__list__title">延迟退房</div>
            </div>
            <div className="home__benefit__list">
              <div className="home__benefit__img">
                <img src={Img_benefit_6} />
              </div>
              <div className="home__benefit__list__title">
                生日期间入住获取礼物
              </div>
            </div>
            <div className="home__benefit__list">
              <div className="home__benefit__img">
                <img src={Img_benefit_8} />
              </div>
              <div className="home__benefit__list__title">提前入住</div>
            </div>
          </div>
          <Link
            to="/zh/member-benefits"
            key="home-member-benefits"
            className="home__benefit__action"
            data-hover="提前入住">
            <span>提前入住</span>
          </Link>
        </div>
      </div>

      <div className="home__earn">
        <div className="container">
          <h2 className="home__earn__title">开始赚取积分</h2>
          <div className="home__earn__subtitle">
            阿雅娜酒店集团旗下所有酒店均参与阿雅娜会员计划。还在等什么？立即加入计划享受所有优惠待遇
          </div>
          <div className="home__earn__action">
            {isAuth0Enabled ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                onClick={() =>
                  loginWithRedirect({
                    authorizationParams: {
                      ui_locales: "zh-CN",
                      screen_hint: "signup",
                      redirect_uri: window.location.href,
                    },
                  })
                }
                key="home-signup"
                className="button button--cta">
                立即加入
              </a>
            ) : (
              <Link
                to="/zh/signup"
                key="home-signup"
                className="button button--cta">
                立即加入
              </Link>
            )}
            {isAuth0Enabled ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                onClick={() =>
                  loginWithRedirect({
                    authorizationParams: {
                      ui_locales: "zh-CN",
                      redirect_uri: window.location.href,
                    },
                  })
                }
                key="home-login"
                className="link link--sm">
                已是会员？立即登陆
              </a>
            ) : (
              <Link to="/zh/login" key="home-login" className="link link--sm">
                已是会员？立即登陆
              </Link>
            )}
          </div>
        </div>
      </div>

      <div className="home__points">
        <div className="container container--600 tablet--hide">
          <h1>阿雅娜会员计划积分</h1>
          <p>消费交易时累积的积分可抵各种奖励</p>
        </div>
        <div className="container">
          <div className="home__points__row">
            <div className="home__points__col">
              <Link
                to="/zh/faq"
                key="faq-home"
                className="home__points__col__inner">
                <h2 className="home__points__col__title">
                  赚取 <span className="mobile--only">&nbsp;积分</span>
                </h2>
                <div className="home__points__col__subtitle">
                  了解如何通过消费累计更多积分
                </div>
                <div className="button button--cta mart--xl">了解更多</div>
              </Link>
            </div>
            <div className="home__points__col">
              <Link to="/zh/reward" className="home__points__col__inner">
                <h2 className="home__points__col__title">
                  赎回 <span className="mobile--only">&nbsp;积分</span>
                </h2>
                <div className="home__points__col__subtitle">
                  换查看您所赚取积分的完整清单
                </div>
                <div className="button button--cta mart--xl">了解更多</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
